/** Dependencies */
import React, { FC } from 'react';
import { SkeletonStyleProps } from 'react-loading-skeleton';

/** Styles */
import * as S from './SkeletonCard.styled';

const SkeletonCard: FC<SkeletonStyleProps> = (skeletonStyleProps) => {
  return (
    <S.Wrapper>
      <S.Image {...skeletonStyleProps} />
      <S.Footer {...skeletonStyleProps} count={2} />
    </S.Wrapper>
  );
};

export default SkeletonCard;
