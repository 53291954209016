import styled, { css } from 'styled-components';
import { WIDTH, ZINDEX } from 'constants/Style';

const NoContentShadow = styled.div<{ isSidebarVisible: boolean }>`
  ${({ theme: { convertVW, sidebar_bg }, isSidebarVisible }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: ${convertVW(24)};
    position: fixed;
    left: ${isSidebarVisible ? convertVW(WIDTH.sidebar) : 0};
    bottom: ${convertVW(-1)};
    width: calc(100% - ${isSidebarVisible ? convertVW(WIDTH.sidebar) : '0px'});
    height: 100%;
    z-index: ${ZINDEX.header - 1};
    box-shadow: 0 ${convertVW(-240)} ${convertVW(240)} 0 ${sidebar_bg} inset;
    transition: left 0.5s, width 0.5s;
  `}
`;

const NoContentShadowLogoWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    width: ${convertVW(240)};
    height: ${convertVW(240)};
  `}
`;

const NoContentShadowImage = styled.img`
  width: 100%;
  height: 100%;
`;

const NoContentShadowTitle = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(72)};
    color: #ed724a;
    text-transform: uppercase;
  `}
`;

const NoContentShadowDescription = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(32)};
    text-align: center;
    width: 75%;
    color: #ed724a;
  `}
`;

const NoContentShadowAcknowledgment = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(32)};
    color: #a26df7;
  `}
`;

export {
  NoContentShadow as Shadow,
  NoContentShadowTitle as ShadowTitle,
  NoContentShadowDescription as ShadowDescription,
  NoContentShadowAcknowledgment as ShadowAcknowledgment,
  NoContentShadowLogoWrapper as ShadowLogoWrapper,
  NoContentShadowImage as ShadowImage,
};
