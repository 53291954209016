/** Dependencies */
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

/** Features */
import ApproveModal from 'features/ApproveModal/ApproveModal';
import CopyLimitModal from 'features/CopyLimitModal/CopyLimitModal';
import SkeletonList from 'features/SkeletonList/SkeletonList';

/** Hooks */
import { useAppSelector } from 'hooks/UseAppSelector';

/** Constants */
import { ROUTE } from 'constants/Routes';
import { ENDPOINTS } from 'constants/EndPoints';

/** Components */
import Card from 'components/Card/Card';

/** Types */
import { ICard } from 'components/Card/types';

/** Hooks */
import { useAppDispatch } from 'hooks/UseAppDispatch';

/** Utilities */
import {
  capitalizeFirstLetters,
  flattenResponse,
  toastOptions,
} from 'utilities/functions';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

/** Store */
import { selectSortItem } from 'store/slices/Sort.slice';
import { selectColumnCount } from 'store/slices/Pagination.slice';
import {
  selectCopyCount,
  selectFilterIndustries,
  selectFilterSubjects,
  setIsCopyCountRequestTriggered,
  setIsHeaderBottomSideDisabled,
} from 'store/slices/Header.slice';
import { setModal } from 'store/slices/Modal.slice';
import { setIsCountsRequestTriggered } from 'store/slices/Sidebar.slice';

/** Api */
import { getRequest, postRequest, putRequest } from 'api/apiClient';

/** Styles */
import * as S from 'pages/Listing/Listing.styled';
import NoContent from 'features/NoContent/NoContent';

interface IProps {
  isMyApprovedPage?: boolean;
  isSavedPage?: boolean;
  isMyPendingPage?: boolean;
  isApprovedPage?: boolean;
  isMyDisapprovedPage?: boolean;
  isDisapprovedPage?: boolean;
  isPendingPage?: boolean;
  isArchivePage?: boolean;
  isErrorPage?: boolean;
}

const Listing: FC<IProps> = ({
  isMyApprovedPage = false,
  isMyDisapprovedPage = false,
  isMyPendingPage = false,
  isSavedPage = false,
  isApprovedPage = false,
  isDisapprovedPage = false,
  isPendingPage = false,
  isArchivePage = false,
  isErrorPage = false,
}) => {
  const { user, isUserDesigner, isUserAdmin, isUserDefault, plans } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const sortOption = useAppSelector(selectSortItem);
  const columnCount = useAppSelector(selectColumnCount);
  const filterIndustries = useAppSelector(selectFilterIndustries);
  const filterSubjects = useAppSelector(selectFilterSubjects);
  const copyCount = useAppSelector(selectCopyCount);

  const selectedPlan = plans.find(
    (plan) => plan.Uid === user?.Account?.CurrentSubscription?.Plan?.Uid
  );

  const isPro = selectedPlan?.Name === 'Pro';

  const noContentTexts = {
    title:
      isMyApprovedPage || isApprovedPage
        ? 'Approved'
        : isMyPendingPage || isPendingPage
        ? 'Pending'
        : isMyDisapprovedPage || isDisapprovedPage
        ? 'Disapproved'
        : isSavedPage
        ? 'Favorites'
        : isArchivePage
        ? 'Archived'
        : '',
    description: isMyApprovedPage
      ? 'You currently have no approved designs. Submit your creations and get them reviewed for approval!'
      : isApprovedPage
      ? 'There is currently no approved designs.'
      : isMyPendingPage
      ? 'You currently have no pending designs in your queue. Keep an eye out for future opportunities or upload new designs for review.'
      : isPendingPage
      ? 'There is currently no pending designs in the queue.'
      : isMyDisapprovedPage
      ? 'You currently have no disapproved designs. Keep up the great work, and continue submitting your creative ideas!'
      : isDisapprovedPage
      ? 'There is currently no disapproved designs.'
      : isSavedPage
      ? 'You haven`t added any designs to your favorites yet. Browse our collection and save your favorite designs for easy access later!'
      : isArchivePage
      ? 'There is currently no archived designs.'
      : '',
    acknowledgment: isMyPendingPage
      ? 'Thank you for your continued creativity and contributions!'
      : '',
  };

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [designs, setDesigns] = useState<ICard[]>();
  const [likesWrapperObjectId, setLikesWrapperObjectId] = useState<number>(0);
  const [likes, setLikes] = useState<number[]>([]);

  const returnFilters = useCallback((): string => {
    let likedIdsFilter = '&filters[id][$in]=0';
    likes.map((like) => {
      likedIdsFilter += `&filters[id][$in]=${like}`;
    });
    likedIdsFilter = isSavedPage ? likedIdsFilter : '';

    let subCategoryFilter = '';
    filterSubjects.map((subject) => {
      subCategoryFilter += `&filters[subCategories][id][$in]=${subject.id}`;
    });

    let industryFilter = '';
    filterIndustries.map((industry) => {
      industryFilter += `&filters[industries][id][$in]=${industry.id}`;
    });

    const sortFilter =
      sortOption.key === 'random'
        ? `&randomSort=true`
        : `&sort[0]=${sortOption.key}:${sortOption.order}`;

    return isArchivePage
      ? ''
      : `${likedIdsFilter}${subCategoryFilter}${industryFilter}${sortFilter}`;
  }, [
    isArchivePage,
    sortOption,
    filterIndustries,
    filterSubjects,
    isSavedPage,
    likes,
  ]);

  const getLikes = async (): Promise<void> => {
    const response = await getRequest(
      ENDPOINTS.SYSTEM_USER_WITH_UID(user?.Uid)
    );

    setLikesWrapperObjectId(response.data.data[0]?.documentId);
    setLikes(
      flattenResponse(flattenResponse(response.data.data)[0]?.likes).map(
        (like) => like.id
      ) ?? []
    );
  };

  const getDesigns = async (): Promise<void> => {
    setIsPageLoading(true);

    const link = (): string => {
      if (isApprovedPage || isMyApprovedPage)
        return `${ENDPOINTS.APPROVED_DESIGNS}${
          isMyApprovedPage ? ENDPOINTS.MY_DESIGNS(user?.Uid) : ''
        }`;
      else if (isDisapprovedPage || isMyDisapprovedPage)
        return `${ENDPOINTS.DISAPPROVED_DESIGNS}${
          isMyDisapprovedPage ? ENDPOINTS.MY_DESIGNS(user?.Uid) : ''
        }`;
      else if (isPendingPage || isMyPendingPage)
        return `${ENDPOINTS.PENDING_DESIGNS}${
          isMyPendingPage ? ENDPOINTS.MY_DESIGNS(user?.Uid) : ''
        }`;
      else if (isSavedPage) return ENDPOINTS.APPROVED_DESIGNS;
      else if (isArchivePage) return ENDPOINTS.ARCHIVED_DESIGNS;
      else return ENDPOINTS.APPROVED_DESIGNS;
    };

    const response = await getRequest(`${link()}${returnFilters()}`);

    setDesigns(flattenResponse(response.data.data));
    setIsPageLoading(false);
  };

  const handleLikeClick = async (id: number): Promise<void> => {
    if (!isUserDesigner) {
      const newLikes = likes.some((like) => like === id)
        ? likes.filter((like) => like !== id)
        : [...likes, id];

      await putRequest(ENDPOINTS.LIKE_DESIGN(likesWrapperObjectId), {
        likes: [...newLikes],
        userId: user.Uid,
      }).then(async () => {
        await getDesigns();
        await getLikes();
      });
    }
  };

  const handleDesignApprove = async (
    id: string,
    type: string
  ): Promise<void> => {
    const approveModalParam = type === 'suspend' ? null : type !== 'disapprove';
    const isArchive = type === 'archive';
    const isUnarchive = type === 'unarchive';

    const response = await getRequest(
      isArchivePage ? ENDPOINTS.ARCHIVED_DESIGN(id) : ENDPOINTS.DESIGN(id)
    );

    const design = flattenResponse([response.data.data])[0];

    const alterDesignerTotalBalance = async (): Promise<void> => {
      await putRequest(
        ENDPOINTS.DESIGNER_USER_WITH_ID(design.designerUser.documentId),
        {
          totalBalance:
            design.designerUser.totalBalance + design.designerUser.fixedFee,
        }
      );
    };

    const createTransaction = async (): Promise<void> => {
      await postRequest(ENDPOINTS.TRANSACTIONS, {
        designerUser: design.designerUser.documentId,
        balanceDelta: design.designerUser.fixedFee,
        totalBalance:
          design.designerUser.totalBalance + design.designerUser.fixedFee,
      });
    };

    const returnContent = (): ReactElement => {
      return (
        <ApproveModal
          type={type}
          isApproved={design.isApproved}
          designerName={design?.designerUser?.fullName}
          designName={design.subjectLine}
          onCancel={() => dispatch(setModal({ isVisible: false }))}
          onConfirm={async (message: string) =>
            isArchive || isUnarchive
              ? await postRequest(
                  isArchive
                    ? ENDPOINTS.ARCHIVE_DESIGN
                    : ENDPOINTS.UNARCHIVE_DESIGN,
                  {
                    documentId: id,
                  },
                  undefined,
                  true
                ).then(async () => {
                  await getDesigns();
                  await getLikes();
                  dispatch(setIsCountsRequestTriggered(true));
                  dispatch(setModal({ isVisible: false }));
                  toast(
                    `${capitalizeFirstLetters(type)} process successful`,
                    toastOptions('👏')
                  );
                })
              : await putRequest(ENDPOINTS.DESIGN(id), {
                  isApproved: approveModalParam,
                  message,
                }).then(async () => {
                  approveModalParam && (await alterDesignerTotalBalance());
                  approveModalParam && (await createTransaction());
                  await getDesigns();
                  await getLikes();
                  dispatch(setIsCountsRequestTriggered(true));
                  dispatch(setModal({ isVisible: false }));
                  toast(
                    `${capitalizeFirstLetters(type)} process successful`,
                    toastOptions('👏')
                  );
                })
          }
        />
      );
    };

    dispatch(
      setModal({
        isVisible: true,
        title: `${capitalizeFirstLetters(type)} Design`,
        content: returnContent(),
      })
    );
  };

  const handleDesignEdit = (id: string): void => {
    navigate(ROUTE.EDIT(id));
  };

  const handleDesignCopy = async (): Promise<string | void> => {
    if (isUserDefault && !isPro) {
      if (copyCount > 0) {
        const userResponse = await getRequest(
          ENDPOINTS.SYSTEM_USER_WITH_UID(user.Uid)
        );

        await putRequest(
          ENDPOINTS.SYSTEM_USER_WITH_ID(userResponse.data.data[0].documentId),
          {
            copyCount: copyCount - 1,
          }
        );

        dispatch(setIsCopyCountRequestTriggered(true));
      } else {
        dispatch(
          setModal({
            isVisible: true,
            title: `Copy Limit Reached`,
            content: <CopyLimitModal />,
          })
        );
        return 'update';
      }
    }
  };

  useEffect(() => {
    (isUserDefault || isUserAdmin) && void getLikes();
  }, [isUserDefault, isUserAdmin, location.pathname]);

  useEffect(() => {
    void getDesigns();
  }, [
    user,
    location.pathname,
    likes,
    filterIndustries,
    filterSubjects,
    sortOption,
  ]);

  useEffect(() => {
    dispatch(setIsHeaderBottomSideDisabled(false));

    return () => {
      dispatch(setIsHeaderBottomSideDisabled(true));
    };
  }, []);

  return (
    <S.Wrapper columnCount={columnCount}>
      {isPageLoading ? (
        <SkeletonList />
      ) : designs?.length && designs?.length > 0 && !isErrorPage ? (
        <>
          {designs?.map((card, i) => (
            <Card
              key={i}
              id={card.id}
              title={card.subjectLine}
              image={card.image.url}
              message={card.message}
              figmaObject={card.figmaObject}
              designerUser={card.designerUser}
              likeCount={isUserDesigner ? card.likedUsers.length : undefined}
              isLiked={likes.some((like) => like === card.id)}
              isAdminCard={!!isUserAdmin}
              isDesignerCard={!!isUserDesigner}
              isEditButtonShown={
                (!!isUserDesigner || !!isUserAdmin) && !card.isApproved
              }
              onLike={handleLikeClick}
              onCopy={handleDesignCopy}
              onEdit={() => handleDesignEdit(card.documentId)}
              onUnArchive={
                isArchivePage
                  ? () => handleDesignApprove(card.documentId, 'unarchive')
                  : undefined
              }
              onArchive={
                isApprovedPage
                  ? () => handleDesignApprove(card.documentId, 'archive')
                  : undefined
              }
              onApprove={
                isApprovedPage || isArchivePage
                  ? undefined
                  : () => handleDesignApprove(card.documentId, 'approve')
              }
              onSuspend={
                isPendingPage || isArchivePage || isApprovedPage
                  ? undefined
                  : () => handleDesignApprove(card.documentId, 'suspend')
              }
              onDisapprove={
                isDisapprovedPage || isArchivePage || isApprovedPage
                  ? undefined
                  : () => handleDesignApprove(card.documentId, 'disapprove')
              }
            />
          ))}
          {designs?.length < columnCount && (
            <SkeletonList
              cardCount={columnCount - designs.length}
              duration={0}
            />
          )}
        </>
      ) : (
        <NoContent texts={noContentTexts} />
      )}
    </S.Wrapper>
  );
};

export default Listing;
