/** Dependencies */
import React, { FC } from 'react';
import { SkeletonStyleProps } from 'react-loading-skeleton';

/** Components */
import SkeletonCard from 'components/SkeletonCard/SkeletonCard';

const SkeletonList: FC<
  SkeletonStyleProps & {
    cardCount?: number;
  }
> = (skeletonStyleProps) => {
  return (
    <>
      {Array.from(new Array(skeletonStyleProps?.cardCount ?? 8)).map(
        (item, i) => (
          <SkeletonCard key={i} {...skeletonStyleProps} />
        )
      )}
    </>
  );
};

export default SkeletonList;
