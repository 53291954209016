/** Dependencies */
import React, { FC } from 'react';

/** Images */
import logo from 'assets/images/svg/logo-without-text.svg';

/** Styles */
import * as S from './NoContent.styled';
import { useAppSelector } from 'hooks/UseAppSelector';
import { selectIsSidebarVisible } from 'store/slices/Sidebar.slice';
import SkeletonList from 'features/SkeletonList/SkeletonList';

interface NoContentProps {
  texts?: {
    title: string;
    description: string;
    acknowledgment: string;
  };
}

const NoContent: FC<NoContentProps> = ({ texts }) => {
  const isSidebarVisible = useAppSelector(selectIsSidebarVisible);

  const shadowTitle = texts?.title;
  const shadowDescription = texts?.description;
  const shadowAcknowledgment = texts?.acknowledgment;

  return (
    <>
      <S.Shadow isSidebarVisible={isSidebarVisible}>
        <S.ShadowLogoWrapper>
          <S.ShadowImage src={logo} alt={'logo'} />
        </S.ShadowLogoWrapper>
        <S.ShadowTitle>No {shadowTitle} Designs</S.ShadowTitle>
        <S.ShadowDescription>{shadowDescription}</S.ShadowDescription>
        <S.ShadowAcknowledgment>{shadowAcknowledgment}</S.ShadowAcknowledgment>
      </S.Shadow>
      <SkeletonList duration={0} cardCount={4} />
    </>
  );
};

export default NoContent;
