import styled, { css } from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonCardWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: relative;
    aspect-ratio: 2 / 3;
    background: #fff;
    border-radius: ${convertVW(8)};
    padding: ${convertVW(16)} ${convertVW(16)} ${convertVW(12)};
    box-shadow: 0 ${convertVW(8)} ${convertVW(12)} ${convertVW(-2)}
        rgba(82, 82, 82, 0.1),
      0 ${convertVW(4)} ${convertVW(4)} ${convertVW(-4)} rgba(82, 82, 82, 0.1);
    border: ${convertVW(1)} solid #eee;
  `}
`;

const SkeletonCardImage = styled(Skeleton)`
  ${({ theme: { convertVW } }) => css`
    width: 100%;
    height: calc(100% - ${convertVW(80)});
    border-radius: ${convertVW(6)};
  `}
`;

const SkeletonCardFooter = styled(Skeleton)`
  ${({ theme: { convertVW } }) => css`
    height: ${convertVW(20)};
    margin-top: ${convertVW(10)};
  `}
`;

export {
  SkeletonCardWrapper as Wrapper,
  SkeletonCardImage as Image,
  SkeletonCardFooter as Footer,
};
