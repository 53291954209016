const ENDPOINTS = {
  /** Designs */
  DESIGN: (id: string) => `/designs/${id}?populate=*`,
  MY_DESIGNS: (userId: string) =>
    `&filters[designerUser][userId][$eq]=${userId}`,
  APPROVED_DESIGNS: `/designs?populate=*&filters[isApproved][$eq]=true`,
  DISAPPROVED_DESIGNS: `/designs?populate=*&filters[isApproved][$eq]=false`,
  PENDING_DESIGNS: `/designs?populate=*&filters[isApproved][$null]=true`,
  ARCHIVED_DESIGNS: `/unpublished-designs`,
  ARCHIVED_DESIGN: (documentId: string) => `/unpublished-designs/${documentId}`,
  ARCHIVE_DESIGN: `/unpublish-design`,
  UNARCHIVE_DESIGN: `/publish-design`,
  NOT_APPROVED_DESIGNS: `&filters[$or][0][isApproved][$eq]=false&filters[$or][1][isApproved][$null]=true`,
  DESIGNS: `/designs?populate=*`,

  /** Users */
  DESIGNER_USERS: '/designer-users?populate=*',
  SYSTEM_USERS: '/system-users?populate=*',
  SYSTEM_USER_WITH_UID: (uid: string) =>
    `/system-users?populate=*&filters[userId][$eq]=${uid}`,
  SYSTEM_USER_WITH_ID: (userId: string) => `/system-users/${userId}?populate=*`,
  DESIGNER_USER_WITH_UID: (uid: string) =>
    `/designer-users?&filters[userId][$eq]=${uid}`,
  DESIGNER_USER_WITH_ID: (userId: string) => `/designer-users/${userId}`,
  LIKE_DESIGN: (likesObjectId: number) => `/system-users/${likesObjectId}`,

  /** Transactions */
  TRANSACTIONS: '/transactions',
  MY_TRANSACTIONS: (userId: string) =>
    `/transactions?sort[0]=createdAt:desc&filters[designerUser][userId][$eq]=${userId}`,

  /** Side Menu Items */
  SIDE_MENU_ITEMS: '/side-menu-items?populate=*',

  /** Tags */
  CATEGORIES: `/categories?populate=*`,
  INDUSTRIES: `/industries?populate=*`,
  SUB_CATEGORIES: `/sub-categories?populate=*`,
  SUB_CATEGORIES_BY_CATEGORY: (categoryId: number) =>
    `/sub-categories?populate=*&filters[category][id][$eq]=${categoryId}`,
  BRANDS: `/brands`,
  EMAIL_TAGS: `/email-tags`,

  /** Media */
  UPLOAD: '/upload',

  /** Sorts */
  SORTS: '/sorts?populate=*',
};

export { ENDPOINTS };
